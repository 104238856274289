<template>
    <div class="blue-bg not-found">
        <h1>502</h1>
        <h2>Bad gateway</h2>
        <p>Try again later</p>
        <p>
            <a class="button" href="/">Back</a>
        </p>
    </div>
</template>

<style lang="scss">
@import "./src/assets/scss/base/base.scss";
@import "./src/assets/scss/not-found.scss";
</style>
